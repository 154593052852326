var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[_c('slider-front',{attrs:{"sliders":_vm.sliders,"lang":_vm.lang}}),_c('div',{staticClass:"container-fluid"},[(_vm.isAuthenticated)?_c('div',{staticClass:"row mt-5 hide-in-movil"},[_c('div',{staticClass:"col-12 text-center"},[_c('b-button',{attrs:{"variant":"primary","to":{
						name: 'TournamentCreate',
						params: { lang: _vm.lang, console: _vm.console },
					}}},[_vm._v(_vm._s(_vm.$t('createTournament')))])],1)]):_vm._e(),_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"row my-3"},[(category.tournaments.length > 0)?_c('div',{staticClass:"col-12 wrapper-title-tournament"},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(category.name))]),(_vm.isAuthenticated)?_c('b-button',{staticClass:"hide-in-pc",attrs:{"to":{
						name: 'TournamentCreate',
						params: { lang: _vm.lang, console: _vm.console },
					},"variant":"primary"}},[_vm._v(_vm._s(_vm.$t('createTournament')))]):_vm._e()],1):_vm._e(),(category.tournaments.length > 0)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},_vm._l((category.tournaments),function(tournament){return _c('div',{key:tournament.id,staticClass:"col-3 col-md-2 mb-3"},[_c('div',{staticClass:"tournament"},[_c('router-link',{attrs:{"to":{
									name: 'TournamentHome',
									params: {
										lang: _vm.lang,
										console: _vm.console,
										tournamentSlug: tournament.slug,
									},
								}}},[_c('img',{staticClass:"img-fluid img-tournament",attrs:{"src":tournament.image,"alt":tournament.name}}),_c('h6',{staticClass:"text-center my-2"},[_vm._v(_vm._s(tournament.name))])]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('small',{staticClass:"text-muted"},[_c('i',{staticClass:"fa fa-eye mr-2"}),_vm._v(_vm._s(tournament.views)+"\n\t\t\t\t\t\t\t\t")]),_c('tournament-options',{attrs:{"slug":tournament.slug}})],1)],1)])}),0)]):_vm._e()])}),_c('carousel-users-online'),_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"wrapper-title-tournament"},[_c('h6',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('notices')))])])]),_vm._l((_vm.notices),function(notice){return _c('div',{key:notice.id,staticClass:"col-6 col-sm-3 col-md-4 mb-3"},[_c('div',{staticClass:"tournament"},[_c('router-link',{attrs:{"to":{
							name: 'Notice',
							params: { lang: _vm.lang, console: _vm.console, slug: notice.slug },
						}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":notice.image,"alt":notice.title}}),_c('strong',{staticClass:"d-block text-center w-100 py-3"},[_vm._v(_vm._s(notice.title))])]),_c('div',{staticClass:"d-flex px-2 flex-wrap justify-content-between align-items-center mb-2"},[_c('hr',{staticClass:"mb-2 mt-0 w-100"}),_c('b-button',{attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.$t('readMore')))]),_c('div',{staticClass:"text-right"},[_c('div',[_vm._v(_vm._s(notice.reviews)+" "+_vm._s(_vm.$t('comments')))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(notice.created_at))])])],1)],1)])}),_c('div',{staticClass:"col-12 text-center py-5"},[_c('b-button',{attrs:{"size":"lg","variant":"primary"}},[_vm._v(_vm._s(_vm.$t('seeAllNews')))])],1)],2),_c('ranking-primary',{attrs:{"users":_vm.rankingPlayers,"teams":_vm.rankingTeams,"console":_vm.console,"lang":_vm.lang}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 wrapper-title-tournament"},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('lastMatches')))])])]),_c('next-matchs',{attrs:{"lastMatches":_vm.lastMatches}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }