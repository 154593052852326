<template>
	<div class="w-100">
		<slider-front :sliders="sliders" :lang="lang" />
		<div class="container-fluid">
			<div class="row mt-5 hide-in-movil" v-if="isAuthenticated">
				<div class="col-12 text-center">
					<b-button
						variant="primary"
						:to="{
							name: 'TournamentCreate',
							params: { lang: lang, console: console },
						}"
						>{{ $t('createTournament') }}</b-button
					>
				</div>
			</div>
			<div class="row my-3" v-for="category in categories" :key="category.id">
				<div
					class="col-12 wrapper-title-tournament"
					v-if="category.tournaments.length > 0"
				>
					<h5 class="title">{{ category.name }}</h5>
					<b-button
						v-if="isAuthenticated"
						:to="{
							name: 'TournamentCreate',
							params: { lang: lang, console: console },
						}"
						class="hide-in-pc"
						variant="primary"
						>{{ $t('createTournament') }}</b-button
					>
				</div>
				<div class="col-12" v-if="category.tournaments.length > 0">
					<div class="row">
						<div
							class="col-3 col-md-2 mb-3"
							v-for="tournament in category.tournaments"
							:key="tournament.id"
						>
							<div class="tournament">
								<router-link
									:to="{
										name: 'TournamentHome',
										params: {
											lang: lang,
											console: console,
											tournamentSlug: tournament.slug,
										},
									}"
								>
									<img
										:src="tournament.image"
										:alt="tournament.name"
										class="img-fluid img-tournament"
									/>
									<h6 class="text-center my-2">{{ tournament.name }}</h6>
								</router-link>
								<div class="d-flex justify-content-between align-items-center">
									<small class="text-muted">
										<i class="fa fa-eye mr-2"></i>{{ tournament.views }}
									</small>
									<tournament-options :slug="tournament.slug" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Carrusel de usuarios en linea -->
			<carousel-users-online />
			<!-- Noticias -->
			<div class="row py-5">
				<div class="col-12">
					<div class="wrapper-title-tournament">
						<h6 class="title">{{ $t('notices') }}</h6>
					</div>
				</div>
				<div
					class="col-6 col-sm-3 col-md-4 mb-3"
					v-for="notice in notices"
					:key="notice.id"
				>
					<div class="tournament">
						<router-link
							:to="{
								name: 'Notice',
								params: { lang: lang, console: console, slug: notice.slug },
							}"
						>
							<img :src="notice.image" :alt="notice.title" class="img-fluid" />
							<strong class="d-block text-center w-100 py-3">{{
								notice.title
							}}</strong>
						</router-link>
						<div
							class="d-flex px-2 flex-wrap justify-content-between align-items-center mb-2"
						>
							<hr class="mb-2 mt-0 w-100" />
							<b-button variant="primary">{{ $t('readMore') }}</b-button>
							<div class="text-right">
								<div>{{ notice.reviews }} {{ $t('comments') }}</div>
								<small class="text-muted">{{ notice.created_at }}</small>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 text-center py-5">
					<b-button size="lg" variant="primary">{{
						$t('seeAllNews')
					}}</b-button>
				</div>
			</div>
			<!-- Ranking -->
			<ranking-primary
				:users="rankingPlayers"
				:teams="rankingTeams"
				:console="console"
				:lang="lang"
			/>
			<!-- Proximos partidos -->
			<div class="row">
				<div class="col-12 wrapper-title-tournament">
					<h5 class="title">{{ $t('lastMatches') }}</h5>
				</div>
			</div>
			<next-matchs :lastMatches="lastMatches" />
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import SliderFront from '../partials/Slider.vue'
import CarouselUsersOnline from '../partials/CarouselUsersOnline.vue'
import RankingPrimary from '../partials/RankingPrimary.vue'
import NextMatchs from '../partials/NextMatchs.vue'
import TournamentOptions from './partials/DropDownTournamentOptions'

export default {
	components: {
		SliderFront,
		CarouselUsersOnline,
		RankingPrimary,
		NextMatchs,
		TournamentOptions,
	},
	data() {
		return {
			sliders: [],
			categories: [],
			notices: [],
			rankingPlayers: [],
			rankingTeams: [],
			lastMatches: [],
		}
	},
	computed: {
		...mapGetters(['loading', 'lang', 'console', 'isAuthenticated']),
	},
	watch: {
		$route(to, from) {
			if (from.params.console !== to.params.console) {
				this.fetchData()
			}
		},
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		fetchData() {
			const console = this.$route.params.console
			const path = `${this.lang}/console/${console}/home`
			this.$axios.get(path).then((response) => {
				const data = response.data.data
				this.sliders = data.sliders
				this.categories = data.categories
				this.notices = data.notices
				this.rankingPlayers = data.ranking_players
				this.rankingTeams = data.ranking_teams
				this.lastMatches = data.last_matches
			})
		},
	},
}
</script>
